<script setup lang="ts">
import type { ButtonColor } from "~/types";
interface Props {
  color?: ButtonColor;
}
defineProps<Props>();
const emits = defineEmits<{
  click: [];
}>();
</script>

<template>
  <ButtonBase
    class="fixed z-10 rounded-full w-12 h-12 leading-[0]"
    :color="color"
    @click="emits('click')"
  >
    <slot />
  </ButtonBase>
</template>
