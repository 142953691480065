<script setup lang="ts">
import type { ButtonColor } from "~/types";
interface Props {
  color?: ButtonColor;
  type?: "button" | "submit";
}
withDefaults(defineProps<Props>(), {
  color: "default",
  type: "button",
});
const emits = defineEmits<{
  click: [];
}>();
</script>

<template>
  <button
    class="cursor-pointer hover:saturate-[3]"
    :class="`button-${color}`"
    :type="type"
    @click="emits('click')"
  >
    <slot />
  </button>
</template>

<style scoped>
.button {
  &-default {
    @apply bg-gray-500 text-white;
  }
  &-primary {
    /* bg-blue-500 は コントラスト比が合格しない */
    @apply bg-primary text-white;
  }
  &-secondary {
    /* bg-orange-500 は コントラスト比が合格しない */
    @apply bg-secondary text-white;
  }
}
</style>
